import React, {useState} from 'react';
import CourseManagementComponent from '../components/courseManagement';
// import SideNavigation from '../components/sideNavigation';

function courseManagement() {
  const [imageLibraryOpen, setImageLibraryOpen] = useState(false);
  const [couserWizard, setCourseWizard] = useState(true);
  const [newCourse, setNewCourseWizard] = useState(false);
  const [thumbnailId, setThumbnailId] = useState(null);
  return (
    <div className="page_content">
      {/* <SideNavigation pageName="COURSES-MANAGEMENT" /> */}
      {couserWizard && (
        <CourseManagementComponent
          setOpenImageLib={() => {
            setImageLibraryOpen(true);
            setCourseWizard(false);
          }}
          setCloseImageLib={() => {
            setImageLibraryOpen(false);
            setCourseWizard(true);
          }}
          thumbnailId={thumbnailId}
          newCourse={newCourse}
        />
      )}
    </div>
  );
}
export default courseManagement;
