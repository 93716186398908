/* eslint-disable no-misleading-character-class */
import React, {useState, useEffect, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import styles from './DrawerCST.module.scss';
import DrawerFailScreen from '../../shared/drawerFailScreen';
import StatusLabel from '../../shared/StatusLabel';
import SearchListComponent from '../../shared/searchListComponent/index';
import LoadingShimmerCustom from '../../shared/LoadingShimmerCustom';
import {
  handleErrorStatesChinese,
  handleErrorStatesKorean,
  handleErrorStatesFrench,
  handleErrorStatesSpanish,
  handleErrorStatesEnglish,
} from '../../utils/CategoriesSkillsTagsManagementUtils/CSTLandingPage';
import {dummyFunction} from '../../utils/commonUtil';
import {
  drawerCalculatedHeight,
  drawerOverlayCalculatedHeight,
  mobDrawerToOrignalState,
  pullbox,
} from '../../utils/commonUtil';
import {
  handleCreateCSTSelectTypeDropdown,
  handleCreateErrorStatesEnglish,
  handleCreateErrorStatesSecondary,
  handleCreateButtonState,
} from '../../utils/CategoriesSkillsTagsManagementUtils/CreatCST';
import skillsIcon from '../../../styles/images/type_skill_cst.svg';
import tagsIcon from '../../../styles/images/type_tag_cst.svg';
import categoryIcon from '../../../styles/images/type_category_cst.svg';

const DrawerComponentCST = (props) => {
  const [isSaveBtnDisable, setIsSaveBtnDisable] = useState(true);
  const {
    isEditDrawer,
    drawerDetails = {},
    drawerOpen = false,
    drawerClose,
    isAPIFailed = false,
    drawerTryAgainCall = dummyFunction,
    drawerIdForBrokenLink = '',
    translationNames,
    language,
    handleTranslationChange,
    handleEnglishTranslationChange,
    refreshList,
    learningPathsDrawerList,
    coursesDrawerList,
    isLoadingCoursesList = false,
    isLoadingLearningPathList = false,
    closeDrawerModal,
    isEnglishTranslationEmpty,
    setEnglishTranslationEmpty,
    translationNamesIntialCopy,
    handleCloseCST,
    handleOnClickEditCourse,
    handleOnClickEditLearningPath,
    list,
    setEnglishTranslationDuplicetName,
    englishTranslationDuplicetName,
    selctedCST,
    nameErrorStateEnglish,
    setNameErrorStateEnglish,
    setNameErrorStateSpanish,
    setNameErrorStateSecondary,
    englishTranslationInvalidCharacter,
    setEnglishTranslationInvalidCharacter,
    setNotificationData,
    createBtnEnabled,
    setCreateBtnEnabled,

    //createNEWCSTMOdal
    openAndCloseCST,
    handleCSTCreatModelCloseOpen,
    mouseHoverState,
    setMouseHoverState,
    selectTypeText,
    setSelectTypeText,
    openSelectTypeDropdown,
    setOpenSelectTypeDropdown,
    handleEnglishTranslationCreateCST,
    englishTranslationCreateCST,
    secondaryTranslationCreateCST,
    handleCreateNewCSTButtonSaveChanges,
    isCreateEnglishTranslationEmpty,
    setCreateEnglishTranslationEmpty,
    isCreateSpanishTranslationEmpty,
    isCreateSecondaryTranslationEmpty,
    setCreateSecondaryTranslationEmpty,
    CreateEnglishTranslationDuplicetName,
    setCreateEnglishTranslationDuplicetName,

    CreateMaxCharErrorMessageSpanish,
    CreateMaxCharErrorMessageSecondary,
    setCreateMaxCharErrorMessageSecondary,
    CreateMaxCharErrorMessageEnglish,
    setCreateMaxCharErrorMessageEnglish,

    nameErrorStateChineseCreate,
    nameErrorStateSpanishCreate,
    nameErrorStateSecondaryCreate,

    setNameErrorStateSecondaryCreate,

    nameErrorStateEnglishCreate,
    setNameErrorStateEnglishCreate,
    nameErrorStateTamilCreate,

    setSecondaryTranslationCreateCSTInvalidCharacter,
    setEnglishTranslationCreateCSTInvalidCharacter,

    // Secondary Language
    handleSecondaryTranslationCreateCST,
    setCreateSecondaryTranslationLength,

    setIsCreateButtonEnable,
    isCreateButtonEnable
  } = props;

  //console.log(">>>> >>>> DrawerComponentCST >>>> props",props)
  const router = useHistory();
  const {query = {}} = router.location;

  const [isInfoIisClicked, setInfoIisClicked] = useState(false);
  const [isCoursesDrawerList, setCoursesDrawerList] = useState(true);
  const [isLearningPathsDrawerList, setLearningPathsDrawerList] = useState(true);

  // Error States
  const [englishTranslationLength, setEnglishTranslationLength] = useState('');
  const [hindiTranslationLength, setHindiTranslationLength] = useState('');
  const [chineseTranslationLength, setChineseTranslationLength] = useState('');
  const [koreanTranslationLength, setKoreanTranslationLength] = useState('');
  const [frenchTranslationLength, setFrenchTranslationLength] = useState('');
  const [spanishTranslationLength, setSpanishTranslationLength] = useState('');

  const [tamilTranslationLength, setTamilTranslationLength] = useState('');
  const [bengaliTranslationLength, setBengaliTranslationLength] = useState('');

  const [maxCharErrorMessageEnglish, setMaxCharErrorMessageEnglish] = useState(false);
  const [maxCharErrorMessageChinese, setMaxCharErrorMessageChinese] = useState(false);
  const [maxCharErrorMessageSpanish, setMaxCharErrorMessageSpanish] = useState(false);

  const [drawerHeight, setDrawerHeight] = useState('');
  const [isAllSecondaryInputText, setIsAllSecondaryInputText] = useState(false);
  // const [isCreateButtonEnable, setIsCreateButtonEnable] = useState(false);

  // const regexNameHindi = /[^\u4E00-\u9FFF\u3400-\u4DFF\uF900-\uFAFF0-9a-zA-Z:： -]+/g;
  // const regexNameHindi = /[!^\u4E00-\u9FFF\u3400-\u4DFF\uF900-\uFAFF0-9a-zA-Z:： -]+/g;
  const regexNameEnglish = /[a-zA-Z0-9:： -]+/g;
  const regexNameChinese = /[\u4E00-\u9FFF0-9:： -]+/g;
  const regexName = {
    Spanish: /[\u00C0-\u00FF0-9a-zA-Z:： -]+/g,
    French: /[\u00C0-\u00FF0-9a-zA-Z:： -]+/g,
    Korean: /[\u3131-\uD79D0-9:： -]+/g,
    'Chinese (Simplified)': /[\u4E00-\u9FFF0-9:： -]+/g,
  };

  useEffect(() => {
    if (Object.values(secondaryTranslationCreateCST)?.length == language?.otherLocales?.length) {
      let checkText = Object.values(secondaryTranslationCreateCST).map((item) => {
        return item;
      });
      const checkTextLength = checkText.every((item) => item?.text?.length > 0);
      setIsAllSecondaryInputText(checkTextLength);
    }
  }, [secondaryTranslationCreateCST]);

  useEffect(() => {
    setCoursesDrawerList(true);
    setLearningPathsDrawerList(true);
    setEnglishTranslationEmpty(false);
    setCreateSecondaryTranslationEmpty([]);
    setMaxCharErrorMessageEnglish(false);
    setNameErrorStateEnglish(false);
    setEnglishTranslationDuplicetName(false);
  }, [drawerDetails]);

  useEffect(() => {
    setDrawerHeight(140);
  }, [drawerOpen]);

  useEffect(() => {
    if (drawerHeight >= 600) {
      closeDrawerModal({
        englishTranslationLength,
        chineseTranslationLength,
        koreanTranslationLength,
        frenchTranslationLength,
        spanishTranslationLength,
        drawerClose,
        drawerOpen,
        drawerDetails,
        setEnglishTranslationEmpty,
        translationNamesIntialCopy,
        translationNames,
        setEnglishTranslationDuplicetName,
        englishTranslationDuplicetName,
      });
    }
  }, [drawerHeight]);

  useEffect(() => {
    handleCreateErrorStatesEnglish({
      e: englishTranslationCreateCST,
      setNameErrorStateEnglishCreate,
      setCreateMaxCharErrorMessageEnglish,
      list,
      drawerDetails: [],
      setCreateEnglishTranslationDuplicetName,
      selectTypeText,
      setEnglishTranslationCreateCSTInvalidCharacter,
      nameErrorStateEnglishCreate,
      setIsCreateButtonEnable
    });

    handleCreateErrorStatesSecondary({
      e: secondaryTranslationCreateCST,
      setCreateMaxCharErrorMessageSecondary,
      setNameErrorStateSecondaryCreate,
      setSecondaryTranslationCreateCSTInvalidCharacter,
    });
  }, [selectTypeText]);

  const disabledClick = () => {
    if (
      englishTranslationCreateCST?.length === 0 &&
      isAllSecondaryInputText === false &&
      // frenchTranslationCreateCST?.length === 0 &&
      // spanishTranslationCreateCST?.length === 0 &&
      selectTypeText != 'Select type'
    ) {
      setCreateEnglishTranslationEmpty(true);
      // Dynamic Languages
      language?.otherLocales?.map((item, key) => {
        setCreateSecondaryTranslationEmpty((prevState) => ({
          ...prevState,
          [item?.title]: true,
        }));
      });
    } else if (englishTranslationCreateCST?.length === 0 && selectTypeText != 'Select type') {
      setCreateEnglishTranslationEmpty(true);
    } else if (
      Object.values(secondaryTranslationCreateCST)?.length == language?.otherLocales?.length &&
      selectTypeText != 'Select type'
    ) {
      Object.values(secondaryTranslationCreateCST).map((item) => {
        setCreateSecondaryTranslationEmpty((prevState) => ({
          ...prevState,
          [item?.name]: item?.text?.length > 0 ? false : true,
        }));
      });
    }
  };

  /**
   * Hook that alerts clicks outside of the passed ref
   */
  function useItemOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenSelectTypeDropdown(false);
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const categorySkillTagDropdownRef = useRef(null);
  useItemOutsideAlerter(categorySkillTagDropdownRef);

  const getTranslationName = (item) => {
    return item?.title?.toLowerCase()?.replace(/ .*/, '');
  };
  

  return (
    <React.Fragment>
      {drawerOpen && isEditDrawer && (
        <div id="divParentOfFilter" className={[styles.drawer_container, ''].join(' ')}>
          <div
            onClick={() => {
              setIsSaveBtnDisable(true);
              closeDrawerModal({
                englishTranslationLength,
                chineseTranslationLength,
                koreanTranslationLength,
                frenchTranslationLength,
                spanishTranslationLength,
                drawerClose,
                drawerOpen,
                drawerDetails,
                setEnglishTranslationEmpty,
                translationNamesIntialCopy,
                translationNames,
                setEnglishTranslationDuplicetName,
                englishTranslationDuplicetName,
              });
            }}
            className={styles.custom_overlay}
            style={drawerOverlayCalculatedHeight({drawerHeight: drawerHeight})}
          ></div>
          <div
            className={styles.drawer_wrapper}
            style={drawerCalculatedHeight({drawerHeight: drawerHeight})}
          >
            <div
              className={styles.mob_bar}
              draggable
              onTouchMove={(e) => {
                pullbox({e: e, setDrawerHeight: setDrawerHeight});
              }}
              onTouchEnd={() => {
                mobDrawerToOrignalState({setDrawerHeight: setDrawerHeight});
              }}
              onClick={() => {
                setIsSaveBtnDisable(true);
                closeDrawerModal({
                  englishTranslationLength,
                  chineseTranslationLength,
                  koreanTranslationLength,
                  frenchTranslationLength,
                  spanishTranslationLength,
                  setCreateSecondaryTranslationLength,
                  drawerClose,
                  drawerOpen,
                  drawerDetails,
                  setEnglishTranslationEmpty,
                  setCreateSecondaryTranslationEmpty,
                  translationNamesIntialCopy,
                  translationNames,
                  setEnglishTranslationDuplicetName,
                  englishTranslationDuplicetName,
                });
              }}
            >
              <div className={styles.line_bar}></div>
            </div>
            {!isAPIFailed ? (
              <div className={[styles.middle_container, ''].join(' ')}>
                <div className={styles.header}>
                  <div className={styles.header_icon}>
                    <img
                      src={
                        drawerDetails.type === 'Tag'
                          ? tagsIcon
                          : drawerDetails.type === 'Skill'
                          ? skillsIcon
                          : categoryIcon
                      }
                    ></img>
                  </div>
                  <div className={styles.title}>{drawerDetails?.type}</div>

                  <div
                    className={styles.close_wrapper}
                    onClick={() => {
                      setIsSaveBtnDisable(true);
                      closeDrawerModal({
                        englishTranslationLength,
                        chineseTranslationLength,
                        koreanTranslationLength,
                        frenchTranslationLength,
                        spanishTranslationLength,
                        drawerClose,
                        drawerOpen,
                        drawerDetails,
                        setEnglishTranslationEmpty,
                        setCreateSecondaryTranslationEmpty,
                        translationNamesIntialCopy,
                        translationNames,
                        setEnglishTranslationDuplicetName,
                        englishTranslationDuplicetName,
                      });
                    }}
                  >
                    <div className={styles.close_icon} />
                  </div>
                </div>

                {/* Form Fields */}
                <div className={styles.form_main_wrapper}>
                  {/* <div className={[styles.form_field, styles.category_dropdown].join(' ')}>
                    <div className={styles.label_wrapper}>
                      <div className={styles.label_text}>Category/Skill/Tag</div>
                    </div>
                  </div> */}

                  {/* Primary Language */}
                  <div
                    className={[
                      styles.form_field,
                      isEnglishTranslationEmpty ||
                      englishTranslationDuplicetName ||
                      maxCharErrorMessageEnglish
                        ? styles.error_wrapper
                        : null,
                    ].join(' ')}
                  >
                    <div className={styles.label_wrapper}>
                      <div className={styles.label_text}>
                        {language && language?.marketDefaultLocale?.title}*
                      </div>
                      <div className={styles.tag_text}>Max 50 characters</div>
                    </div>

                    <div className={styles.input_wrapper}>
                      <input
                        type={'text'}
                        placeholder={'Enter Translation'}
                        name={language?.marketDefaultLocale?.title || ''}
                        className={[
                          styles.input,
                          maxCharErrorMessageEnglish ? styles.input_error_black : null,
                          englishTranslationDuplicetName || maxCharErrorMessageEnglish
                            ? styles.error_input
                            : null,
                        ].join(' ')}
                        // maxLength={50}
                        value={translationNames[getTranslationName(language?.marketDefaultLocale) + '_Translation']}
                        onChange={(e) => {
                          setIsSaveBtnDisable(e?.target?.value.length === 0);
                          setEnglishTranslationLength(e.target.value.length);
                          handleEnglishTranslationChange({e, variant: 'courses', currentKey: language?.marketDefaultLocale?.title});
                          handleErrorStatesEnglish({
                            e: e?.target?.value,
                            setNameErrorStateEnglish,
                            setMaxCharErrorMessageEnglish,
                            list,
                            drawerDetails,
                            setEnglishTranslationDuplicetName,
                            setEnglishTranslationInvalidCharacter,
                            setIsSaveBtnDisable,
                          });
                          setEnglishTranslationEmpty(false);
                        }}
                      />

                      {maxCharErrorMessageEnglish ? (
                        <div className={styles.error_message}>Maximum character limit reached</div>
                      ) : isEnglishTranslationEmpty ? (
                        <>
                          <div className={styles.error_icon_red}></div>
                          <div className={styles.error_message}>
                            {language && language?.marketDefaultLocale?.title} Title is mandatory
                          </div>
                        </>
                      ) : null}
                      {englishTranslationDuplicetName ? (
                        <>
                          <div className={styles.error_icon_red}></div>
                          <div className={styles.error_message}>Name already exists</div>
                        </>
                      ) : null}
                    </div>
                  </div>
                  {/* Primary Language */}

                  {/* Secondary Languages */}
                  {language?.otherLocales.map((item, key) => (
                    <div
                      className={[
                        styles.form_field,
                        isCreateSecondaryTranslationEmpty[item?.title] ||
                        CreateMaxCharErrorMessageSecondary[item?.title]
                          ? styles.error_wrapper
                          : null,
                      ].join(' ')}
                      key={key}
                    >
                      {/* {`${regexName?.[item?.title]}`} */}
                      <div className={styles.label_wrapper}>
                        <div className={styles.label_text}>{item?.title}*</div>
                        <div className={styles.tag_text}>Max 50 characters</div>
                      </div>

                      <div className={styles.input_wrapper}>
                        <input
                          type={'text'}
                          placeholder={'Enter Translation'}
                          // maxLength={50}
                          className={
                            mouseHoverState === 'Category' ||
                            mouseHoverState === 'Skill' ||
                            mouseHoverState === 'Tag'
                              ? styles.input
                              : [styles.input].join(' ')
                          }
                          value={translationNames[getTranslationName(item) + '_Translation']}
                          onChange={(e) => {
                            setIsSaveBtnDisable(
                              e?.target?.value.length === 0 || e?.target?.value.length > 50
                            );
                            handleTranslationChange({
                              e,
                              variant: 'courses',
                              currentKey: item?.title,
                            });
                            handleCreateErrorStatesSecondary({
                              e: e.target.value,
                              setCreateMaxCharErrorMessageSecondary,
                              setNameErrorStateSecondaryCreate,
                              setSecondaryTranslationCreateCSTInvalidCharacter,
                              name: item?.title,
                              setCreateBtnEnabled,
                            });
                          }}
                          name={item?.title}
                        />
                        {CreateMaxCharErrorMessageSecondary[item?.title] ? (
                          <div className={styles.error_message}>
                            Maximum character limit reached
                          </div>
                        ) : isCreateSecondaryTranslationEmpty[item?.title] ? (
                          <>
                            <div className={styles.error_icon_red}></div>
                            <div className={styles.error_message}>
                              {item?.title} Title is mandatory
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  ))}
                  {/* Secondary Languages */}
                </div>

                {/* Courses */}
                {coursesDrawerList?.length > 0 ? (
                  <>
                    <div className={styles.refresh_wrapper}>
                      <div
                        className={styles.refresh_txt}
                        onClick={() => {
                          refreshList({
                            variant: 'courses',
                            drawerId: drawerDetails?.id,
                            type: drawerDetails?.type,
                          });
                        }}
                      >
                        Refresh List
                      </div>
                      <div
                        className={styles.refresh_icon}
                        onClick={() => {
                          refreshList({
                            variant: 'courses',
                            drawerId: drawerDetails?.id,
                            type: drawerDetails?.type,
                          });
                        }}
                      />
                    </div>

                    <div className={[styles.img_instances_container, ''].join('')}>
                      <div
                        className={styles.dropdown_container}
                        // onClick={() => handleReplaceAllInstance()}
                      >
                        <div className={styles.top_bar}>
                          <div
                            className={styles.title}
                            onClick={() => {
                              setCoursesDrawerList(!isCoursesDrawerList);
                            }}
                          >
                            Courses in which this {drawerDetails?.type} is used (
                            {coursesDrawerList?.length}){' '}
                            {/* {courseDetails?.length > 0
                           ? `(${courseDetails?.length})`
                           : ""} */}
                          </div>
                          {/* Added "isClick" class here to open banner on click of info btn on drawer */}

                          <div
                            className={[
                              styles.toggle_arrow,
                              styles.big_icon,
                              isCoursesDrawerList ? styles.up_arrow : styles.down_arrow,
                            ].join(' ')}
                            onClick={() => {
                              setCoursesDrawerList(!isCoursesDrawerList);
                            }}
                          ></div>
                        </div>
                        {!isLoadingCoursesList ? (
                          isCoursesDrawerList && (
                            <div className={styles.list_wrapper}>
                              {coursesDrawerList?.map((item, index) => {
                                return (
                                  <div className={styles.list} key={index}>
                                    {/* keep below both class as it is "mt_instances". */}
                                    <div
                                      className={[styles.item_wrapper, styles.mt_instances].join(
                                        ' ',
                                      )}
                                    >
                                      <div
                                        className={[
                                          styles.course_type_icon,
                                          styles.courses_icon,
                                          styles.mt_class,
                                        ].join(' ')}
                                      ></div>
                                      <div className={styles.item}>{item?.title}</div>
                                    </div>

                                    <div className={styles.status_wrapper}>
                                      <StatusLabel item={item} large={true} />
                                    </div>

                                    <div
                                      className={
                                        item?.state != 'Archive'
                                          ? styles.edit_wrapper
                                          : styles.edit_icon_disabled
                                      }
                                      onClick={() => {
                                        item?.state != 'Archive' &&
                                          handleOnClickEditCourse({
                                            id: item?.id,
                                            state: item?.state,
                                          });
                                        item?.state == 'Archive' &&
                                          setNotificationData({
                                            show: true,
                                            type: 'WARNING',
                                            title: 'Course Archived.',
                                            description: `${item?.title} has been archived and cannot be edited.`,
                                          });
                                      }}
                                    >
                                      <div className={styles.edit_icon}></div>
                                    </div>
                                    {/* <div
                                  className={[
                                    styles.instance_status,
                                    item?.courseState == 'Live'
                                      ? styles.live_sts
                                      : item?.courseState == 'Draft'
                                      ? styles.draft_sts
                                      : item.courseState == 'Archive'
                                      ? styles.archive_sts
                                      : item.courseState == 'Offline'
                                      ? styles.offline_sts
                                      : '',
                                  ].join(' ')}
                                >
                                  {item?.courseState}
                                </div> */}
                                  </div>
                                );
                              })}
                            </div>
                          )
                        ) : (
                          <>
                            {(() => {
                              const arr = [];
                              for (let i = 0; i < 4; i++) {
                                {
                                  arr.push(
                                    <div className={[styles.list_wrapper, ''].join(' ')}>
                                      <LoadingShimmerCustom
                                        className={[styles.list, ''].join(' ')}
                                      />
                                    </div>,
                                  );
                                }
                              }
                              return arr;
                            })()}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                ) : null}

                {/* Learning Path */}
                {learningPathsDrawerList?.length > 0 ? (
                  <>
                    {/* Refresh Button */}
                    <div className={styles.refresh_wrapper}>
                      <div
                        className={styles.refresh_txt}
                        onClick={() => {
                          refreshList({
                            variant: 'learningPaths',
                            drawerId: drawerDetails?.id,
                            type: drawerDetails?.type,
                          });
                        }}
                      >
                        Refresh List
                      </div>
                      <div
                        className={styles.refresh_icon}
                        onClick={() => {
                          refreshList({
                            variant: 'learningPaths',
                            drawerId: drawerDetails?.id,
                            type: drawerDetails?.type,
                          });
                        }}
                      />
                    </div>
                    {/* Refresh Button */}
                    <div className={[styles.img_instances_container, ''].join('')}>
                      <div
                        className={styles.dropdown_container}
                        // onClick={() => handleReplaceAllInstance()}
                      >
                        <div className={styles.top_bar}>
                          <div
                            className={styles.title}
                            onClick={() => {
                              setLearningPathsDrawerList(!isLearningPathsDrawerList);
                            }}
                          >
                            Learning Paths in which this {drawerDetails?.type} is used (
                            {learningPathsDrawerList?.length}){' '}
                            {/* {courseDetails?.length > 0
                           ? `(${courseDetails?.length})`
                           : ""} */}
                          </div>
                          {/* Added "isClick" class here to open banner on click of info btn on drawer */}

                          <div
                            className={[
                              styles.toggle_arrow,
                              styles.big_icon,
                              isLearningPathsDrawerList ? styles.up_arrow : styles.down_arrow,
                            ].join(' ')}
                            onClick={() => {
                              setLearningPathsDrawerList(!isLearningPathsDrawerList);
                            }}
                          ></div>
                        </div>
                        {!isLoadingLearningPathList ? (
                          isLearningPathsDrawerList && (
                            <div className={styles.list_wrapper}>
                              {learningPathsDrawerList?.map((item, index) => {
                                return (
                                  <div className={styles.list} key={index}>
                                    {/* keep below both class as it is "mt_instances". */}
                                    <div
                                      className={[styles.item_wrapper, styles.mt_instances].join(
                                        ' ',
                                      )}
                                    >
                                      <div
                                        className={[
                                          styles.course_type_icon,
                                          styles.learning_path_icon,
                                          styles.mt_class,
                                        ].join(' ')}
                                      ></div>
                                      <div className={styles.item}>{item?.title}</div>
                                    </div>

                                    <div className={styles.status_wrapper}>
                                      <StatusLabel item={item} large={true} />
                                    </div>

                                    <div
                                      className={
                                        item?.state != 'Archive'
                                          ? styles.edit_wrapper
                                          : styles.edit_icon_disabled
                                      }
                                      onClick={() => {
                                        item?.state != 'Archive' &&
                                          handleOnClickEditLearningPath({
                                            id: item?.id,
                                            state: item?.state,
                                          });
                                        item?.state == 'Archive' &&
                                          setNotificationData({
                                            show: true,
                                            type: 'WARNING',
                                            title: 'Learning Path Archived.',
                                            description: `${item?.title} has been archived and cannot be edited.`,
                                          });
                                      }}
                                    >
                                      <div className={styles.edit_icon}></div>
                                    </div>
                                    {/* <div
                                  className={[
                                    styles.instance_status,
                                    item?.courseState == 'Live'
                                      ? styles.live_sts
                                      : item?.courseState == 'Draft'
                                      ? styles.draft_sts
                                      : item.courseState == 'Archive'
                                      ? styles.archive_sts
                                      : item.courseState == 'Offline'
                                      ? styles.offline_sts
                                      : '',
                                  ].join(' ')}
                                >
                                  {item?.courseState}
                                </div> */}
                                  </div>
                                );
                              })}
                            </div>
                          )
                        ) : (
                          <>
                            {(() => {
                              const arr = [];
                              for (let i = 0; i < 4; i++) {
                                {
                                  arr.push(
                                    <div className={[styles.list_wrapper, ''].join(' ')}>
                                      <LoadingShimmerCustom
                                        className={[styles.list, ''].join(' ')}
                                      />
                                    </div>,
                                  );
                                }
                              }
                              return arr;
                            })()}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                ) : null}
                {/* Category Message Starts */}
                {coursesDrawerList?.length === 0 && learningPathsDrawerList?.length === 0 ? (
                  <div className={[styles.category_message, styles.default].join(' ')}>
                    <div className={styles.category}>
                      {drawerDetails?.type} not associated with any Instance
                    </div>
                  </div>
                ) : null}
                {/* Category Message Ends */}

                {/* Delete Button */}
                {/* isSaveBtnDisable ? styles.disabled : '' */}
                <div
                  className={
                    isSaveBtnDisable
                      ? [styles.btn_wrapper, styles.disabled].join(' ')
                      : [styles.btn_wrapper].join(' ')
                  }
                >
                  <div
                    className={styles.btn}
                    onClick={() => {
                      setIsSaveBtnDisable(true);
                      closeDrawerModal({
                        englishTranslationLength,
                        setCreateSecondaryTranslationLength,
                        drawerClose,
                        drawerOpen,
                        drawerDetails,
                        setEnglishTranslationEmpty,
                        setCreateSecondaryTranslationEmpty,
                        translationNamesIntialCopy,
                        translationNames,
                        setEnglishTranslationDuplicetName,
                        englishTranslationDuplicetName,
                        isSave: true,
                      });
                    }}
                  >
                    Save
                    <div className={styles.save_icon} />
                  </div>
                </div>

                <div
                  className={[
                    styles.btn_wrapper,
                    coursesDrawerList?.length !== 0 || learningPathsDrawerList?.length !== 0
                      ? styles.disabled
                      : null,
                  ].join(' ')}
                >
                  <div
                    className={styles.btn}
                    onClick={() => {
                      handleCloseCST();
                    }}
                  >
                    Delete
                    <div className={styles.delete_icon} />
                  </div>
                </div>

                {/* Category Message Starts */}
                {coursesDrawerList?.length !== 0 || learningPathsDrawerList?.length !== 0 ? (
                  <div className={[styles.category_message, styles.warning].join(' ')}>
                    <div className={styles.category}>
                      {drawerDetails?.type} cannot be deleted while in use by Courses or Learning
                      Paths.
                    </div>
                  </div>
                ) : null}
                {/* Category Message Ends */}
              </div>
            ) : (
              <div className={[styles.middle_container, ''].join(' ')}>
                <div className={styles.header}>
                  <div className={styles.title}>{selctedCST}</div>
                  <div
                    className={styles.close_wrapper}
                    onClick={() =>
                      closeDrawerModal({
                        englishTranslationLength,
                        chineseTranslationLength,
                        koreanTranslationLength,
                        frenchTranslationLength,
                        spanishTranslationLength,
                        drawerClose,
                        drawerOpen,
                        drawerDetails,
                        setEnglishTranslationEmpty,
                        translationNamesIntialCopy,
                        translationNames,
                        setEnglishTranslationDuplicetName,
                        englishTranslationDuplicetName,
                      })
                    }
                  >
                    <div className={styles.close_icon} />
                  </div>
                </div>
                <DrawerFailScreen
                  drawerId={drawerIdForBrokenLink}
                  drawerTryAgainCall={(item) => {
                    drawerTryAgainCall(item);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}

      {drawerOpen && !isEditDrawer && (
        <div id="divParentOfFilter" className={[styles.drawer_container, ''].join(' ')}>
          <div
            onClick={() => {
              setIsSaveBtnDisable(true);
              closeDrawerModal({
                englishTranslationLength,
                chineseTranslationLength,
                koreanTranslationLength,
                frenchTranslationLength,
                spanishTranslationLength,
                drawerClose,
                drawerOpen,
                drawerDetails,
                setEnglishTranslationEmpty,
                translationNamesIntialCopy,
                translationNames,
                setEnglishTranslationDuplicetName,
                englishTranslationDuplicetName,
              });
            }}
            className={styles.custom_overlay}
            style={drawerOverlayCalculatedHeight({drawerHeight: drawerHeight})}
          ></div>
          <div
            className={styles.drawer_wrapper}
            style={drawerCalculatedHeight({drawerHeight: drawerHeight})}
          >
            <div
              className={styles.mob_bar}
              draggable
              onTouchMove={(e) => {
                pullbox({e: e, setDrawerHeight: setDrawerHeight});
              }}
              onTouchEnd={() => {
                mobDrawerToOrignalState({setDrawerHeight: setDrawerHeight});
              }}
              onClick={() => {
                setIsSaveBtnDisable(true);

                closeDrawerModal({
                  englishTranslationLength,
                  chineseTranslationLength,
                  koreanTranslationLength,
                  frenchTranslationLength,
                  spanishTranslationLength,
                  drawerClose,
                  drawerOpen,
                  drawerDetails,
                  setEnglishTranslationEmpty,
                  translationNamesIntialCopy,
                  translationNames,
                  setEnglishTranslationDuplicetName,
                  englishTranslationDuplicetName,
                });
              }}
            >
              <div className={styles.line_bar}></div>
            </div>
            {!isAPIFailed ? (
              <div className={[styles.middle_container, ''].join(' ')}>
                <div className={styles.header}>
                  <div className={styles.title}>Create New Category/Skill/Tag </div>

                  <div
                    className={styles.close_wrapper}
                    onClick={() => {
                      setIsSaveBtnDisable(true);
                      if (selectTypeText !== 'Select type') {
                        setNotificationData({
                          show: true,
                          type: 'FAILURE',
                          title: `${selectTypeText} failed to add`,
                          description: `${selectTypeText} not added due to insufficient content`,
                        });
                      }
                      closeDrawerModal({
                        englishTranslationLength,
                        chineseTranslationLength,
                        koreanTranslationLength,
                        frenchTranslationLength,
                        spanishTranslationLength,
                        drawerClose,
                        drawerOpen,
                        drawerDetails,
                        setEnglishTranslationEmpty,
                        translationNamesIntialCopy,
                        translationNames,
                        setEnglishTranslationDuplicetName,
                        englishTranslationDuplicetName,
                      });
                    }}
                  >
                    <div className={styles.close_icon} />
                  </div>
                </div>

                {/* Form Fields */}
                <div className={styles.form_main_wrapper}>
                  {/* <div className={[styles.form_field, styles.category_dropdown].join(' ')}>
                    <div className={styles.label_wrapper}>
                      <div className={styles.label_text}>Category/Skill/Tag</div>
                    </div>
                  </div> */}

                  {/* DropDown  */}

                  <div className={styles.form_field}>
                    <div className={styles.label_wrapper}>
                      <div className={styles.label_text}>Category/Skill/Tag*</div>
                    </div>
                    <div
                      className={[styles.select_main_container].join(' ')}
                      ref={categorySkillTagDropdownRef}
                    >
                      <div
                        className={
                          openSelectTypeDropdown
                            ? [styles.select_dropdown, styles.active_dropdown].join(' ')
                            : [styles.select_dropdown].join(' ')
                        }
                        onClick={() => {
                          handleCreateCSTSelectTypeDropdown({
                            setOpenSelectTypeDropdown,
                            openSelectTypeDropdown,
                          });
                        }}
                      >
                        {' '}
                        {/* active_dropdown add this class when downdown open */}
                        <div className={styles.txt}>{selectTypeText}</div>
                        <div
                          className={[
                            openSelectTypeDropdown
                              ? [styles.arrow, styles.up_arrow].join(' ')
                              : [styles.arrow, styles.down_arrow].join(' '),
                          ].join(' ')}
                        />
                      </div>

                      {openSelectTypeDropdown ? (
                        <div
                          className={
                            mouseHoverState != 'Select type'
                              ? [styles.dropdown_main_container, styles.selected].join(' ')
                              : [styles.dropdown_main_container].join(' ')
                          }
                        >
                          <div
                            className={[
                              styles.list,
                              mouseHoverState === 'Category' ? styles.selected : null,
                            ].join(' ')}
                            onMouseOver={() => {
                              setMouseHoverState('Category');
                            }}
                            onMouseOut={() => {
                              // setMouseHoverState('');
                              selectTypeText == 'Select type'
                                ? setMouseHoverState('Select type')
                                : setMouseHoverState(selectTypeText);
                            }}
                            onClick={() => {
                              setSelectTypeText('Category');
                              handleCreateCSTSelectTypeDropdown({
                                setOpenSelectTypeDropdown,
                                openSelectTypeDropdown,
                              });
                            }}
                          >
                            Category
                          </div>
                          <div
                            className={[
                              styles.list,
                              mouseHoverState === 'Skill' ? styles.selected : null,
                            ].join(' ')}
                            onMouseOver={() => {
                              setMouseHoverState('Skill');
                            }}
                            onMouseOut={() => {
                              // setMouseHoverState('');
                              selectTypeText == 'Select type'
                                ? setMouseHoverState('Select type')
                                : setMouseHoverState(selectTypeText);
                            }}
                            onClick={() => {
                              setSelectTypeText('Skill');
                              handleCreateCSTSelectTypeDropdown({
                                setOpenSelectTypeDropdown,
                                openSelectTypeDropdown,
                              });
                            }}
                          >
                            Skill
                          </div>
                          <div
                            className={[
                              styles.list,
                              mouseHoverState === 'Tag' ? styles.selected : null,
                            ].join(' ')}
                            onMouseOver={() => {
                              setMouseHoverState('Tag');
                            }}
                            onMouseOut={() => {
                              //setMouseHoverState('');
                              selectTypeText == 'Select type'
                                ? setMouseHoverState('Select type')
                                : setMouseHoverState(selectTypeText);
                            }}
                            onClick={() => {
                              setSelectTypeText('Tag');
                              handleCreateCSTSelectTypeDropdown({
                                setOpenSelectTypeDropdown,
                                openSelectTypeDropdown,
                              });
                            }}
                          >
                            Tag
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* Primary Language */}
                  <div
                    className={[
                      styles.form_field,
                      isCreateEnglishTranslationEmpty ||
                      CreateEnglishTranslationDuplicetName ||
                      CreateMaxCharErrorMessageEnglish
                        ? styles.error_wrapper
                        : null,
                    ].join(' ')}
                  >
                    <div className={styles.label_wrapper}>
                      <div className={styles.label_text}>
                        {language && language?.marketDefaultLocale?.title}*
                      </div>
                      <div className={styles.tag_text}>Max 50 characters</div>
                    </div>

                    <div className={styles.input_wrapper}>
                      <input
                        type={'text'}
                        placeholder={'Enter Translation'}
                        name={language?.marketDefaultLocale?.title || ''}
                        className={[
                          mouseHoverState === 'Category' ||
                          mouseHoverState === 'Skill' ||
                          mouseHoverState === 'Tag'
                            ? styles.input
                            : [styles.input, styles.disabled].join(' '),
                          englishTranslationDuplicetName || maxCharErrorMessageEnglish
                            ? styles.error_input
                            : null,
                        ].join(' ')}
                        // maxLength={50}
                        value={englishTranslationCreateCST}
                        onChange={(e) => {
                          handleEnglishTranslationCreateCST({
                            text: e.target.value,
                          });
                          handleCreateErrorStatesEnglish({
                            e: e.target.value,
                            setNameErrorStateEnglishCreate,
                            setCreateMaxCharErrorMessageEnglish,
                            list,
                            drawerDetails: [],
                            setCreateEnglishTranslationDuplicetName,
                            selectTypeText,
                            setEnglishTranslationCreateCSTInvalidCharacter,
                            setIsCreateButtonEnable,
                          });
                          setCreateEnglishTranslationEmpty(false);
                        }}
                      />
                      {CreateMaxCharErrorMessageEnglish ? (
                        <div className={styles.error_message}>Maximum character limit reached</div>
                      ) : isCreateEnglishTranslationEmpty ? (
                        <>
                          <div className={styles.error_icon_red}></div>
                          <div className={styles.error_message}>
                            {language && language?.marketDefaultLocale?.title} Title is mandatory
                          </div>
                        </>
                      ) : CreateEnglishTranslationDuplicetName ? (
                        <>
                          <div className={styles.error_icon_red}></div>
                          <div className={styles.error_message}>Name already exists</div>
                        </>
                      ) : null}
                    </div>
                  </div>
                  {/* Primary Language */}

                  {/* Secondary Languages */}
                  {language?.otherLocales.map((item, key) => (
                    <div
                      className={[
                        styles.form_field,
                        isCreateSecondaryTranslationEmpty[item?.title] ||
                        CreateMaxCharErrorMessageSecondary[item?.title]
                          ? styles.error_wrapper
                          : null,
                      ].join(' ')}
                      key={key}
                    >
                      {/* {`${regexName?.[item?.title]}`} */}
                      <div className={styles.label_wrapper}>
                        <div className={styles.label_text}>{item?.title}*</div>
                        <div className={styles.tag_text}>Max 50 characters</div>
                      </div>

                      <div className={styles.input_wrapper}>
                        <input
                          type={'text'}
                          placeholder={'Enter Translation'}
                          // maxLength={50}
                          className={
                            mouseHoverState === 'Category' ||
                            mouseHoverState === 'Skill' ||
                            mouseHoverState === 'Tag'
                              ? styles.input
                              : [styles.input, styles.disabled].join(' ')
                          }
                          onChange={(e) => {
                            handleSecondaryTranslationCreateCST({
                              text: e.target.value,
                              name: item?.title,
                            });
                            handleCreateErrorStatesSecondary({
                              e: e.target.value,
                              setCreateMaxCharErrorMessageSecondary,
                              setNameErrorStateSecondaryCreate,
                              setSecondaryTranslationCreateCSTInvalidCharacter,
                              name: item?.title,
                            });
                            setCreateSecondaryTranslationEmpty((prevState) => ({
                              ...prevState,
                              [item?.title]: false,
                            }));
                          }}
                        />
                        {CreateMaxCharErrorMessageSecondary[item?.title] ? (
                          <div className={styles.error_message}>
                            Maximum character limit reached
                          </div>
                        ) : isCreateSecondaryTranslationEmpty[item?.title] ? (
                          <>
                            <div className={styles.error_icon_red}></div>
                            <div className={styles.error_message}>
                              {item?.title} Title is mandatory
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  ))}
                  {/* Secondary Languages */}

                  {/* Submit Button  */}
                  <div className={styles.btn_wrapper_create}>
                    <button
                      className={[
                        styles.btn,
                        handleCreateButtonState({
                          englishTranslationCreateCST,
                          isCreateEnglishTranslationEmpty,
                          CreateEnglishTranslationDuplicetName,
                          nameErrorStateEnglishCreate,
                          secondaryTranslationCreateCST,
                          isAllSecondaryInputText,
                          CreateMaxCharErrorMessageSecondary,
                          isCreateButtonEnable
                        }) && !isCreateButtonEnable 
                          ? styles.active
                          : styles.disabled,
                      ].join(' ')}
                      onClick={async () => {
                        englishTranslationCreateCST?.length > 0 &&
                        isAllSecondaryInputText &&
                        !isCreateButtonEnable
                          ? await handleCreateNewCSTButtonSaveChanges()
                          : disabledClick();
                      }}
                    >
                      {' '}
                      {/* Added active, disabled class according to req. */}
                      <span>Create</span>
                      <span
                        className={[
                          styles.icon,
                          handleCreateButtonState({
                            englishTranslationCreateCST,
                            isCreateEnglishTranslationEmpty,
                            CreateEnglishTranslationDuplicetName,
                            nameErrorStateEnglishCreate,
                            nameErrorStateTamilCreate,
                            secondaryTranslationCreateCST,
                            isAllSecondaryInputText,
                          })
                            ? styles.add_icon_active
                            : styles.add_icon_disabled,
                        ].join(' ')}
                      ></span>
                    </button>
                  </div>
                </div>

                {/* Category Message Ends */}
              </div>
            ) : (
              <div className={[styles.middle_container, ''].join(' ')}>
                <div className={styles.header}>
                  <div className={styles.title}>Create New Category/Skill/Tag</div>
                  <div
                    className={styles.close_wrapper}
                    onClick={() =>
                      closeDrawerModal({
                        englishTranslationLength,
                        chineseTranslationLength,
                        koreanTranslationLength,
                        frenchTranslationLength,
                        spanishTranslationLength,
                        drawerClose,
                        drawerOpen,
                        drawerDetails,
                        setEnglishTranslationEmpty,
                        translationNamesIntialCopy,
                        translationNames,
                        setEnglishTranslationDuplicetName,
                        englishTranslationDuplicetName,
                      })
                    }
                  >
                    <div className={styles.close_icon} />
                  </div>
                </div>
                <DrawerFailScreen
                  drawerId={drawerIdForBrokenLink}
                  drawerTryAgainCall={(item) => {
                    drawerTryAgainCall(item);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

DrawerComponentCST.propTypes = {};

export default DrawerComponentCST;
