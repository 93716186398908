import React, {useState} from 'react';
import styles from '../styles/pages/Home.module.css';
import CategorySkillsTagsManagementComponent from '../components/categorySkillsTagsManagement';

const CategorySkillsTagsManagement = () => {
  return (
    <div className="page_content">
      <div className={styles.container}>
        <main className={styles.main}>
          <CategorySkillsTagsManagementComponent />
        </main>
      </div>
    </div>
  );
};

CategorySkillsTagsManagement.propTypes = {};

export default CategorySkillsTagsManagement;
