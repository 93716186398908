import React from 'react';
import AudienceManagementComponent from '../components/audienceManagement';
function audienceManagement() {
  return (
    <div className="page_content">
      {/* <SideNavigation pageName="AUDIENCE-MANAGEMENT" />  */}
      <AudienceManagementComponent />
    </div>
  );
}
export default audienceManagement;
