import React, {useEffect, useState, useRef} from 'react';
// import PropTypes from "prop-types";
// import _ from 'lodash';
import Header from '../shared/header';
import DeleteModal from './DeleteModal';
import CoursesModal from './CoursesModal';
import styles from './Audience.module.scss';
import LearningPathModalComponent from './learningPathModal';
import CreateUserGroupComponent from './createUserGroup';
import {connect} from 'react-redux';
import TopBarComponenent from '../audienceManagement/TopBar';
import TableListComponent from '../audienceManagement/TableComponent';
import {findIndex, find} from 'lodash';
import {
  setSelectedNavigationOption,
  setIsUserLoggedIn,
  setCountryList,
  setRemoteConfig,
} from '../../redux/actions/uiActions';
import DrawerComponent from './Drawer';
import {makeRequest} from '../utils/APIsUtils/httpsUtils';
import EmptyScreenComponent from '../shared/emptyScreen';
import {setErrorStateTemplate} from '../../redux/actions/uiActions';
import Notification from '../shared/notification';
import config from '../../config';
import {useHistory} from 'react-router-dom';
import {
  handleFilterApply,
  handleCloseLearningModal,
  handleCloseCoursesModal,
  handleCloseNewUserGroup,
  handleSaveDeleteChanges,
  //_filterData,
  handleSearchInput,
  fetchAudienceGroupList,
  handleCloseDeleteModal,
  selectedAudiGrp,
  fetchAudienceDetails,
} from '../utils/AudienceManagementUtils/LandingPageUtils';
import ManageLearningPathModal from '../learningPathManagement/ManageCourseModal/ManageCourseModal';
import CountrySelection from '../shared/countrySelection';
import CountrySelectionHandleEvents from '../utils/CountrySelection/countrySelectionHandleEvents';

const configData = JSON.parse(localStorage.getItem('configData'));

const AudienceManagementComponent = ({
  setSelectedNavigationOption,
  setIsUserLoggedIn,
  setErrorStateTemplate,
  notificationData,
  countryList,
  setCountryList,
  remoteConfig,
}) => {
  const router = useHistory();
  const {query = {}} = router.location;
  const [isCollapse, setCollapsible] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [isCourses, setCourses] = useState(false);
  const [isLearning, setLearning] = useState(false);
  const [isNewUserGroup, setNewUserGroup] = useState(false);
  const [rowId, setRowId] = useState(0);
  const [search, setSearch] = useState('');
  const [filterDropdown, setFilterDropdown] = useState(false);
  const [isDrawer, setDrawer] = useState(false);
  const [width, setWidth] = useState(0);
  const [predictionList, setPredictionList] = useState([]);
  const [audienceGroupList, setAudienceGroupList] = useState([]);
  const [audienceGroupListCopy, setAudienceGroupListCopy] = useState([]);
  const [isOnline, setOnline] = useState(true);
  const [isSearchFilterEmptyState, setSearchFilterEmptyState] = useState(false);
  const [audienceDetails, setAudienceDetails] = useState({});
  const [predictedSearch, setPredictedSearch] = useState([]);
  const [isDrawerAPIFailed, setDrawerAPIFailed] = useState(false);
  const [drawerIdForBrokenLink, setDrawerIdForBrokenLink] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [countryCodeString, setCountryCodeString] = useState('');
  const [isCountryListUpdated, setIsCountryListUpdated] = useState(false);
  const _filterData = {
    filtersList: [
      {
        filterType: 'RADIO',
        filterTypeData: {
          sectionTitle: 'Sort By',
          sectionData: [
            {
              index: 0,
              name: 'Recent Activity',
              id: 1,
              checked: true,
              filterParameter: 'name',
              filterParameterType: 'default',
              typeOfFilter: 'RADIO',
              paramForAPI: 'Recent_Activity',
            },
            {
              index: 1,
              name: 'A - Z',
              id: 2,
              checked: false,
              filterParameter: 'description',
              filterParameterType: 'ASC',
              typeOfFilter: 'RADIO',
              paramForAPI: 'A_Z',
            },
            {
              index: 2,
              name: 'Z - A',
              id: 3,
              checked: false,
              filterParameter: 'description',
              filterParameterType: 'DESC',
              typeOfFilter: 'RADIO',
              paramForAPI: 'Z_A',
            },
            {
              index: 3,
              name: 'Most Courses',
              id: 4,
              checked: false,
              filterParameter: 'updatedAt',
              filterParameterType: 'TIME-ASC',
              typeOfFilter: 'RADIO',
              paramForAPI: 'most_courses ',
            },
            {
              index: 4,
              name: 'Least Courses',
              id: 5,
              checked: false,
              filterParameter: 'updatedAt',
              filterParameterType: 'TIME-DESC',
              typeOfFilter: 'RADIO',
              paramForAPI: 'least_courses ',
            },
          ],
        },
      },
    ],
  };

  const fetchCountriesList = async () => {
    if (remoteConfig?.user_group_country_targeting) {
      const fetchCountry = await makeRequest({
        method: 'GET',
        endpoint: `/api/v1/market/countries`,
      });
      let country = [];
      if (fetchCountry?.data) {
        country = Object.entries(fetchCountry?.data)
          .map(([countryName, countryCode]) => ({
            countryName,
            countryCode,
            checked: true,
          }))
          .sort((a, b) => (a.name > b.name ? 1 : -1));
        setCountryList(country);
        setIsCountryListUpdated(true);
      }
      return country;
    } else {
      let country = [
        {
          countryName: configData?.name,
          countryCode: configData?.code.toUpperCase(),
          checked: true,
        },
      ];
      setCountryList(country);
      setIsCountryListUpdated(true);
      return country;
    }
  };

  const {updateCountryList, handleSelectAllCountry, handleClearAllCountry, handleCountrySelect} =
    CountrySelectionHandleEvents();

  useEffect(() => {
    const initialize = async () => {
      await fetchCountriesList();
    };
    setDrawer(false);
    initialize();
    setIsUserLoggedIn(true);
    setSelectedNavigationOption('AUDIENCE-MANAGEMENT');
    setOnline(navigator.onLine);
  }, []);

  const [filterData, setFilterData] = useState(_filterData);

  window.addEventListener('online', () => {
    setOnline(true);
    setErrorStateTemplate({
      status: false,
      variant: 'networkError',
    });
    window.location.reload();
  });

  window.addEventListener('offline', () => {
    setOnline(false);
    setErrorStateTemplate({
      status: true,
      variant: 'networkError',
    });
  });

  useEffect(() => {
    if (search && search.length && search.length >= 1) {
      let result;
      result = audienceGroupListCopy.filter((user) =>
        user.audienceGrpName.toLowerCase().includes(search.toLowerCase()),
      );
      //for checking length of audi group name
      result?.sort(function (one, other) {
        return one?.audienceGrpName?.length - other?.audienceGrpName?.length;
      });
      //for checking the serach char index in audi group name
      result?.sort(function (one, other) {
        return (
          one.audienceGrpName?.toLowerCase().indexOf(search?.toLowerCase()) -
          other.audienceGrpName?.toLowerCase().indexOf(search?.toLowerCase())
        );
      });
      setAudienceGroupList(result);
    } else {
      setAudienceGroupList(audienceGroupListCopy);
    }
  }, [search, audienceGroupListCopy]);

  const handleCountryClearAll = () => {
    const newCountryList = handleClearAllCountry(countryList);
    setCountryList(newCountryList);
  };
  const handleCountrySelectAll = () => {
    const newCountryList = handleSelectAllCountry(countryList);
    setCountryList(newCountryList);
  };

  const handleCountrySelectA = (country) => {
    const newCountryList = handleCountrySelect(country, countryList);
    setCountryList(newCountryList);
  };

  useEffect(async () => {
    if (isCountryListUpdated) {
      let countryCodeString = '';
      countryList.forEach((element) => {
        countryCodeString += element.checked ? `&countryCodes=${element.countryCode}` : '';
      });
      setDrawer(false);
      setCountryCodeString(countryCodeString);
      const res = await fetchAudienceGroupList({
        sortBy: 'oldest_to_newest',
        makeRequest,
        setSearchFilterEmptyState,
        setAudienceGroupList,
        setAudienceGroupListCopy,
        setErrorStateTemplate,
        isOnline,
        setIsLoading,
        countryCodeString,
        remoteConfig: remoteConfig,
      });
      if (Object.keys(query).length > 0 && Array.isArray(res) && res.length > 0) {
        selectedAudiGrp({
          item: query?.UserGroupId,
          isDrawer,
          audienceDetails,
          setDrawer,
          setAudienceDetails,
          makeRequest,
          setDrawerAPIFailed,
          drawerIdForBrokenLink,
          setDrawerIdForBrokenLink,
          audienceGroupList: res,
          countryList,
        });
      }
    }
  }, [countryList, isCountryListUpdated]);

  const handleFilterInternal = (params) => {
    const {paramForAPI} = params[0];
    let updatedAudGrpList = audienceGroupList.slice();
    if ('most_courses' === paramForAPI.trim()) {
      updatedAudGrpList.sort((a, b) => b.courseCount - a.courseCount);
    } else if ('least_courses' === paramForAPI.trim()) {
      updatedAudGrpList.sort((a, b) => a.courseCount - b.courseCount);
    } else if ('A_Z' === paramForAPI.trim()) {
      updatedAudGrpList.sort((a, b) => a.audienceGrpName.localeCompare(b.audienceGrpName));
    } else if ('Z_A' === paramForAPI.trim()) {
      updatedAudGrpList.sort((a, b) => b.audienceGrpName.localeCompare(a.audienceGrpName));
    } else if ('Recent_Activity' === paramForAPI.trim()) {
      updatedAudGrpList.sort((a, b) => a.createdAt - b.createdAt);
    }

    setAudienceGroupList(updatedAudGrpList);
  };

  return (
    <React.Fragment>
      <div className={[styles.audience_mgt_wrapper, isNewUserGroup].join(' ')}>
        {/* <div className={styles.header_main_wrapper}>
         <div className={styles.header_wrapper}>
           <div className={styles.heading}>Audience Management</div>
         </div>
       </div> */}
        {/* Header Component */}

        <Header
          headerName="User Group Management"
          headerXSName=""
          btnName1="Cancel"
          btnName2="Save changes"
          isHeaderXS={false}
          isBtn1={false}
          isBtn2={false}
        />
        {/* Header Component */}
        {/* Learning Empty error container */}
        {/* {audienceGroupList?.length == 0 &&  (
          <div className={[styles.am_empty_screen_wrapper, ''].join(' ')}>
            <div className={styles.inner_wrapper_empty}>
              <div className={styles.empty_screen}>
                <div className={[styles.image_wrapper, styles.am_image].join(' ')}></div>

                <div className={styles.content_wrapper}>
                  <div className={styles.label}>User Group Management</div>
                  <div className={styles.content}>
                    Ability to target courses at specific groups of ABOs coming later in 2022.
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} */}
        {/* End of empty screen */}

        {
          <div className={[styles.audience_wrapper].join(' ')}>
            <TopBarComponenent
              list={audienceGroupList}
              fetchList={(params) => {
                fetchAudienceGroupList({
                  sortBy: `${params[0]?.paramForAPI?.toLowerCase()}`,
                  makeRequest,
                  setSearchFilterEmptyState,
                  setAudienceGroupList,
                  setAudienceGroupListCopy,
                  setErrorStateTemplate,
                  isOnline,
                  setIsLoading,
                  remoteConfig: remoteConfig,
                });
              }}
              searchListFunction={(e) => {
                if (e != '') {
                  setSearch(e?.target?.value?.trim());
                } else if (e == '') {
                  setSearch('');
                }
              }}
              placeholderName={'Search User Group'}
              setPredictedSearch={setPredictedSearch}
              predictedSearch={predictedSearch}
              handleSearchInput={(event) => {
                handleSearchInput({
                  event,
                  setSearch,
                  setPredictedSearch,
                  audienceGroupList,
                });
              }}
              handleFilterApply={(params) => {
                handleFilterInternal(params);
                // handleFilterApply({
                //   params,
                //   filterData,
                //   findIndex,
                //   fetchAudienceGroupList,
                //   makeRequest,
                //   setSearchFilterEmptyState,
                //   setAudienceGroupList,
                //   setAudienceGroupListCopy,
                //   setErrorStateTemplate,
                //   isOnline,
                //   setIsLoading,
                // });
              }}
              filterData={filterData}
              isOpenSubCategories={true}
              isDrawer={isDrawer}
              setDrawer={setDrawer}
              remoteConfig={remoteConfig}
            />

            <div className={styles.inner_wrapper}>
              <div className={styles.left_wrapper}>
                {/* Conuntry Selection */}
                {remoteConfig?.user_group_country_targeting ? (
                  <div className={styles.country_selection}>
                    <CountrySelection
                      title="Show Country Availability"
                      countryList={countryList}
                      handleClearAll={handleCountryClearAll}
                      handleSelectAll={handleCountrySelectAll}
                      handleCountrySelect={handleCountrySelectA}
                    ></CountrySelection>
                  </div>
                ) : null}

                {/* Audience group list */}
                <TableListComponent
                  isLoading={isLoading}
                  UGList={true}
                  list={audienceGroupList}
                  EmptyScreenComponent={EmptyScreenComponent}
                  isSearchFilterEmptyState={isSearchFilterEmptyState}
                  onClickAudienceGrp={(e, item, ele) => {
                    const countryCodeIds = audienceGroupList
                      .filter((usrGrp) => item === usrGrp.audienceGrpName)
                      .map((countryAudGrpIds) => countryAudGrpIds.countryAudGrpIds);

                    const countryCodeAllIds = Object.entries(countryCodeIds[0]).map(
                      ([countryCode, countryId]) => ({
                        countryCode,
                        countryId,
                      }),
                    );
                    if (ele?.courseCount === 0 && ele?.learningPathCount === 0) {
                      let data = {
                        courses: [],
                        learningPaths: [],
                        countryCodeAllIds,
                        audienceGroupId: ele?.audienceGroupId || '',
                        audienceGrpName: ele?.audienceGrpName,
                        courseCount: ele?.courseCount,
                        learningPathCount: ele?.learningPathCount,
                        audienceGrpDesc: ele?.audienceGrpDescription,
                      };
                      setAudienceDetails(data);
                      setDrawer(true);
                    } else {
                      selectedAudiGrp({
                        e,
                        item,
                        isDrawer,
                        audienceDetails,
                        setDrawer,
                        setAudienceDetails,
                        makeRequest,
                        setDrawerAPIFailed,
                        drawerIdForBrokenLink,
                        setDrawerIdForBrokenLink,
                        audienceGroupList,
                        countryList,
                      });
                    }
                  }}
                  drawerDetails={audienceDetails}
                  drawerOpen={isDrawer}
                  drawerIdForBrokenLink={drawerIdForBrokenLink}
                />
              </div>

              <DrawerComponent
                drawerDetails={audienceDetails}
                drawerOpen={isDrawer}
                drawerClose={setDrawer}
                isAPIFailed={isDrawerAPIFailed}
                drawerTryAgainCall={(drawerId) => {
                  if (query?.UserGroupId?.length > 0) {
                    let countryCodeString = '';
                    let userGroups = query?.UserGroupId;
                    for (let i = 0; i < userGroups.length; i++) {
                      countryCodeString += `audGrpIds=${userGroups[i].countryId}&`;
                    }
                  }

                  fetchAudienceDetails({
                    AudiId: drawerId,
                    makeRequest,
                    setAudienceDetails,
                    setDrawer,
                    setDrawerAPIFailed,
                    drawerIdForBrokenLink,
                    setDrawerIdForBrokenLink,
                    countryCodesString: countryCodeString,
                  });
                }}
                drawerIdForBrokenLink={drawerIdForBrokenLink}
                remoteConfig={remoteConfig}
              />
            </div>
          </div>
        }
        {/* Delete Modal */}
        {isDelete ? (
          <DeleteModal
            closeDeleteModal={handleCloseDeleteModal({setDelete})}
            saveDeleteChanges={handleSaveDeleteChanges({setDelete})}
          />
        ) : null}
        {/* Delete Modal */}

        {/* Courses Modal */}
        {isCourses ? (
          <CoursesModal closeCoursesModal={handleCloseCoursesModal({setCourses, isCourses})} />
        ) : null}
        {/* Courses Modal */}

        {/* Learning Modal */}
        {isLearning ? (
          <LearningPathModalComponent
            closeLearningModal={handleCloseLearningModal({setLearning, isLearning})}
          />
        ) : null}
        {/* Learning Modal */}
      </div>
      {/* Create User Group */}
      {isNewUserGroup ? (
        <CreateUserGroupComponent closeNewUserGroup={handleCloseNewUserGroup({setNewUserGroup})} />
      ) : null}
      {/* Create User Group */}

      {/* Manage Learning Path Starts */}
      <ManageLearningPathModal />
      {/* Manage Learning Path Ends */}
      {notificationData?.show && <Notification />}
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => ({
  isUserLoggedIn: state && state.uiState && state.uiState.isUserLoggedIn,
  notificationData: state && state.uiState && state.uiState.notificationData,
  countryList: state && state.uiState && state.uiState.countryList,
  remoteConfig: state && state.uiState && state.uiState.remoteConfig,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedNavigationOption: (options) => dispatch(setSelectedNavigationOption(options)),
  setIsUserLoggedIn: (options) => dispatch(setIsUserLoggedIn(options)),
  setErrorStateTemplate: (options) => dispatch(setErrorStateTemplate(options)),
  setCountryList: (options) => dispatch(setCountryList(options)),
  setRemoteConfig: (options) => dispatch(setRemoteConfig(options)),
});

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(AudienceManagementComponent),
);
