import React from 'react';
// import LearningPathComponent from "../components/learningPathManagement";
import CreateNewLearningPath from '../components/learningPathManagement/createNewLearningPath';
import LearningPathModalComponent from '../components/audienceManagement/learningPathModal';
import LearningPathComponent from '../components/learningPathManagement';

function learningPathModal() {
  return (
    <div className="page_content">
      {/* <SideNavigation pageName="AUDIENCE-MANAGEMENT" />  */}
      {/* <LearningPathComponent/> */}
      {/* <CreateNewLearningPath /> */}
      {/* <LearningPathModalComponent /> */}

      <LearningPathComponent />
    </div>
  );
}
export default learningPathModal;
