import {colors} from '@material-ui/core';
import React from 'react';
import SideNavigation from '../components/sideNavigation';
import Dashboard from '../components/portal';
const Dasboard = () => {
  return (
    <div className="page_content">
      {/* <SideNavigation pageName="DASHBOARD" /> */}
      <Dashboard />
    </div>
  );
};

// Dasboard.propTypes = {};

export default Dasboard;
