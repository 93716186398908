import React from 'react';
import styles from '../Content.module.scss';

const CourseLearningPathWizardHandle = (props) => {
  const {
    courseMode,
    imageThumbnailButtonClick,
    imageId,
    setNewThumbnailId,
    setCloseImageLib,
    setCroppedImage_16_9,
    setCroppedImage_1_1,
    setCroppedImageData,
    setCroppedImageData11,
    setCroppedImageData169,
    handleAddNewImage,
    router,
    learningPathMode,
    Strings,
    mainImg,
    setMainImageBackup,
    newCourseData,
    newLPData,
  } = props;
  const onClickHandle = () => {
    const url = '/create-course-wizard';
    let tempObj = JSON.parse(sessionStorage.getItem('path'));
    //const newWindow = window.open(url + '?id=' + id, '_blank', 'noopener,noreferrer');
    let subheader =
      newCourseData?.courseState == 'Draft'
        ? 'course_description'
        : newCourseData?.courseState == 'Live' || newCourseData?.courseState == 'Offline'
        ? 'edit'
        : 'course_description';

    if (tempObj?.pathname === '/create-course-wizard') {
      courseMode === 'FROM_COURSE_WIZARD' &&
        router.push({
          pathname: url,
          search: `?id=${
            newCourseData.courseId ? newCourseData.courseId : '0'
          }&sub_header=${subheader}`,
          //query: {actionName: ""},
        });
    } else {
      learningPathMode === 'FROM_LEARNING_PATH' &&
        router.push({
          pathname: '/newLearningPath',
          search: `?id=${
            newLPData.learningPathId ? newLPData.learningPathId : null
          }&sub_header=learning_path_description&fromcontentmgt=true`,
          //added from_content_mgt to avoid API call on CreateNewLearningPath page of fetchLpDetails
          //query: {actionName: ""},
        });
    }

    //courseMode === 'FROM_COURSE_WIZARD' && router.push('/Coursesmanagement');
  };
  return (
    <React.Fragment>
      <div
        className={[styles.btn_wrapper, styles.btn_wrapper_xs].join(' ')}
        onClick={() => {
          if (courseMode === 'FROM_COURSE_WIZARD' || learningPathMode === 'FROM_LEARNING_PATH') {
            if (imageId && imageThumbnailButtonClick) {
              setNewThumbnailId(imageId);
              setMainImageBackup(mainImg);
              setCloseImageLib();
              onClickHandle();
            }
          } else {
            setCroppedImage_16_9(null);
            setCroppedImage_1_1(null);
            setCroppedImageData(null);
            setCroppedImageData11(null);
            setCroppedImageData169(null);
            handleAddNewImage();
          }
        }}
      >
        <div
          className={[
            styles.btn,
            (courseMode === 'FROM_COURSE_WIZARD' || learningPathMode === 'FROM_LEARNING_PATH') &&
            !imageThumbnailButtonClick
              ? styles.disabled
              : '',
          ].join(' ')}
        >
          {courseMode === 'FROM_COURSE_WIZARD' ||
          learningPathMode === 'FROM_LEARNING_PATH' ? null : (
            <div className={styles.add} />
          )}
          <div className={styles.txt}>
            {courseMode === 'FROM_COURSE_WIZARD'
              ? 'Add Course Thumbnail Image'
              : learningPathMode === 'FROM_LEARNING_PATH'
              ? 'Add Learning Path Thumbnail Image'
              : Strings.NEW_IMG}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CourseLearningPathWizardHandle;
